import styled from '@emotion/styled';
import Markdown from 'markdown-to-jsx';

import { Layout, MiddleText } from 'UI';
import { MAX_MOB_WIDTH, MAX_TABLET_WIDTH } from 'constants/sizes';

export const Container = styled(Layout)`
  background: var(--black);
  color: var(--white);
  min-height: calc(100vh - 7.5em);
  grid-template-rows: auto auto 1fr auto;
  row-gap: 20px;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    min-height: calc(100vh - 5em);
  }
`;

export const LocationsWrapper = styled(Layout)`
  grid-column: 1 / -1;
`;

export const Title = styled.h2`
  grid-column: 1/-1;
`;

export const Text = styled(Markdown)`
  display: grid;
  grid-auto-flow: row;
  gap: 1em;
  grid-column: 1/6;
  color: var(--gray);
  position: relative;
  margin-bottom: 1rem;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/5;
  }

  a {
    color: var(--red);
  }

  p {
    white-space: pre-wrap;
  }
`;

export const Space = styled.div`
  grid-column: 1/-1;
  height: 100%;
  width: 100%;
  align-self: start;
`;

export const Contact = styled.div`
  grid-column: span 3;
  grid-row: 6;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-row: auto;
  }
`;

export const Name = styled.p``;

export const Email = styled(MiddleText)`
  color: var(--red);
`;

export const Location = styled.div`
  color: var(--white);
  display: grid;
  grid-template-columns: 33% 67%;
  grid-column: span 3;
  gap: 1em;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: span 3;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const LocationTitle = styled.span`
  font-weight: 500;
`;

export const LocationText = styled(Markdown)`
  white-space: pre-wrap;

  p {
    margin-bottom: 1.5em;
  }

  u {
    display: inline-block;
    color: var(--red);
  }
`;
