import React, { FC } from 'react';

import { IPageArticle, IPageContact } from 'interfaces/page';
import { IComponentWidgetText } from 'interfaces/widgets';

import {
  Container, Title, Contact, Name, Email, Space, Text,
  LocationsWrapper, Location, LocationText, LocationTitle,
} from './ContactsBlock.styled';

interface IContactsBlock {
  data: IPageArticle;
  contacts: IPageContact[];
  locations: IComponentWidgetText[];
}

const ContactsBlock: FC<IContactsBlock> = ({
  data,
  contacts = [],
  locations = [],
}) => (
  <Container withPaddings>
    <Title>Locations</Title>
    <LocationsWrapper gridOnly>
      {locations.map(({ id, title, text }) => (
        <Location key={`${id}-${title}`}>
          <LocationTitle>{title}</LocationTitle>
          <LocationText>{text}</LocationText>
        </Location>
      ))}
    </LocationsWrapper>

    <Space />

    <Title>{data.title}</Title>
    <Text>{data.article}</Text>

    {contacts.map(({ id, name, email }: IPageContact) => (
      <Contact key={`contacts-${id}`}>
        <Name>{name}</Name>
        <Email>
          <a href={`mailto:${email}`}>{email}</a>
        </Email>
      </Contact>
    ))}
  </Container>
);

export default ContactsBlock;
