import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IComponentWidgetText } from 'interfaces/widgets';
import { IPage, IPageArticle, IPageContact } from 'interfaces/page';

import Head from 'components/Layout/Head/Head';

import ContactsBlock from 'components/blocks/ContactsBlock/ContactsBlock';

const query = graphql`
  query {
    page: strapiContacts {
      meta {
        ...StrapiMetaFragment
      }
      data {
        ...StrapiArticleFragment
      }
      contacts {
        ...StrapiContactFragment
      }
      locations {
        text
        title
      }
    }
  }
`;

interface IContactsPage extends IPage {
  data: IPageArticle;
  contacts: IPageContact[];
  locations: IComponentWidgetText[];
}

interface IQueryContactsPage {
  page: IContactsPage;
}

const ContactsPage: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IQueryContactsPage) => {
      const siteInfo = data.page.meta;

      return (
        <>
          <Head
            title={siteInfo.title}
            description={siteInfo.description}
            preview={siteInfo.preview}
            slug="contacts"
          />
          <ContactsBlock
            data={data.page.data}
            contacts={data.page.contacts}
            locations={data.page.locations}
          />
        </>
      );
    }}
  />
);

export default ContactsPage;
